.btn-link:disabled {
    color: #6c757d;
    font-weight: bold;
    border: 1px solid #ccc;
    padding: 3px;
}

.area {
    height: 500px;
}
