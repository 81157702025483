body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.activecustomer {
    border: solid 1px #D6DBDF;
    background-color: #D6DBDF;
}

.btn-link:disabled {
    color: #6c757d;
    font-weight: bold;
    border: 1px solid #ccc;
    padding: 3px;
}

.area {
    height: 500px;
}

